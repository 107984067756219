























































































//TODO: Eğer kullanıcı eğitimi daha önce satın almış ise uyarı göster. Tekrar satın almasın.

import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";
import "@/types";

@Component({
  components: {
    Breadcrumbs,
    Confirm
  },
  metaInfo(this: CourseCatalogDetail) {
    return {
      title: this.pageTitle
    };
  }
})
export default class CourseCatalogDetail extends Vue {
  pageTitle = "";

  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Eğitim Kataloğu",
      exact: true,
      to: { name: "course-catalog" }
    },
    {
      text: ""
    }
  ];

  panel = [0, null, null];

  submitDisabled = false;

  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user() {
    return this.$store.getters["auth/user"];
  }

  get isRegistered() {
    const now = firebase.firestore.Timestamp.now().toDate();

    return now <= this.courseReg.finishDate && now >= this.courseReg.startDate;
  }

  course: Course = {
    id: "",
    code: "",
    urlCode: "",
    name: "",
    desc: "",
    img: "",
    instructor: "",
    startDate: new Date(),
    finishDate: null,
    price: 0,
    disabled: false
  };

  courseReg = {
    id: "",
    courseId: "",
    startDate: new Date(),
    finishDate: new Date()
  };

  async getCourse() {
    try {
      const courseQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courses")
        .where("urlCode", "==", this.$route.params.courseUrlCode)
        .where("disabled", "==", false)
        .limit(1)
        .get();

      const course = courseQs.docs[0];

      if (course.exists) {
        this.course = {
          id: course.id,
          code: course.data().code,
          urlCode: course.data().urlCode,
          name: course.data().name,
          desc: course.data().desc,
          img: course.data().img,
          instructor: course.data().instructor,
          startDate: course.data().startDate.toDate(),
          finishDate: course.data().finishDate
            ? course.data()?.finishDate?.toDate()
            : null,
          price: course.data().price,
          disabled: course.data().disabled
        };

        // Sayfa başlığını değiştir
        this.pageTitle = this.course.name;
      }

      this.breadcrumbs[this.breadcrumbs.length - 1].text = this.course.name;

      if (this.userSignedIn) {
        this.getCourseRegistration();
      }
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Eğitim bilgileri alınamadı. ${err}`
      });
    }
  }

  async getCourseRegistration() {
    try {
      const regQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courseRegs")
        .where("courseId", "==", this.course.id)
        .where("uid", "==", this.user.id)
        .where("isActive", "==", true)
        .limit(1)
        .get();

      if (!regQs.empty) {
        const reg = regQs.docs[0];

        this.courseReg.id = reg.id;
        this.courseReg.courseId = reg.data().courseId;
        this.courseReg.startDate = reg.data().startDate.toDate();
        this.courseReg.finishDate = reg.data().finishDate.toDate();
      }

      console.log(this.courseReg);
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Eğitim kaydı bilgileri alınamadı. ${err}`
      });
    }
  }

  addToCart() {
    // Kullanıcı yoksa girişe yönlendir
    if (!this.userSignedIn) {
      this.$router.push({
        name: "sign-in",
        query: {
          returnUrl: this.$route.fullPath
        }
      });

      return;
    }

    this.$store.dispatch("cart/addItem", {
      id: this.course.id,
      title: this.course.name,
      price: this.course.price
    });

    this.$store.dispatch("cart/saveCart");

    this.$notify({
      type: "success",
      text: `"${this.course.name}" sepete eklendi.`,
      position: "bottom center"
    } as NotificationOptions);
  }

  async mounted() {
    await this.getCourse();
  }
}
